import { Show, JSXElement, splitProps  } from "solid-js";
import { Stack, Typography, Divider, Card, CardMedia } from "@suid/material";
import { StarRating } from './SmallShowStars';
import { CommentIcon, QuoteIcon } from "../icons" 

interface RatingCommentProps {
    header?: string;
    comment: string;
    logoUrl?: string;
    ratedBy?: string;
    ratedByPosition?: string;
    showDivider?: boolean;
}

const RatingComment = ({ comment, header = "", logoUrl = "", ratedBy = "", ratedByPosition = "", showDivider = true }: RatingCommentProps) => {

    return (
        <>
            {/* Show the header with a comment icon, if header is included*/}
            <Show
                when={header}
            >
                <Typography variant="h6" > <CommentIcon sx={{ px: 1, py: 1 }} />{header}</Typography>
            </Show>

            {/* Show the comment */}
            <Show
                when={logoUrl}
                fallback={
                    <Stack direction="column" sx={{ px: 4, py: 1 }}>
                        <Typography variant="body2" > {comment} </Typography>
                    </Stack>
                }
            >
                <Card sx={{ margin: 1, display: 'flex' }}>
                    <Stack direction="column"  >

                        <Typography variant="body2" sx={{ px: 3, py: 1 }} gutterBottom> <QuoteIcon />{comment} <QuoteIcon /></Typography>

                        <Show
                            when={ratedBy}
                        >
                            <Stack direction="row" sx={{ px: 4 }} >

                                <Stack direction="column"   >
                                    <Typography variant="body2" align="right">{ratedBy}</Typography>
                                    <Typography variant="body2" align="right" color="text.secondary">{ratedByPosition}</Typography>
                                </Stack>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 30, height: 30, px: 1, py: 1 }}
                                    image={logoUrl}
                                    alt="Logo"
                                />
                            </Stack>
                        </Show>
                    </Stack>
                </Card>
            </Show>

            {/* Show a divider*/}
            <Show
                when={showDivider}
            >
                <Divider sx={{ px: 1, py: 1 }} />
            </Show>
        </>
    );
}


interface RatingDetailsProps {
 
    rating: number;
    showDivider?: boolean;
    children: JSXElement;
}

const RatingDetails = ( props: RatingDetailsProps) => {
    const [local] = splitProps(props, ['children'])
    !props.showDivider || props.showDivider === undefined ? props.showDivider = true : props.showDivider = false 
    return (
        <>

            {/* Show the comment */}

            <Stack direction="column" sx={{ px: 1, py: 1 }} alignItems="center"    >
                <Typography variant="body2" > {props.children} </Typography>
                <Stack direction="row"   >
                    <StarRating rating={props.rating} />

                </Stack>
            </Stack>



            {/* Show a divider*/}
            <Show
                when={props.showDivider}
            >
                <Divider orientation="vertical" flexItem />
            </Show>
        </>
    );
}

export { RatingComment, RatingDetails } 
